import React, { useEffect, useState } from "react";
import Image from "next/image";
import { IconWithLabelProps } from "./types";
import classes from "./IconWithLabel.module.scss";
const DEFAULT_FOLDER = "logos";
export const GENERATION_FOLDER = "generations";

export const IconWithLabel = ({ option, folder = DEFAULT_FOLDER }: IconWithLabelProps) => {
    const [size, setSize] = React.useState(28);
    useEffect(() => {
        if (folder === GENERATION_FOLDER) {
            setSize(50);
        }
    }, [folder]);
    return (
        <div
            className={classes.IconLabel}
            style={{
                width: size,
                height: size
            }}
        >
            <Image
                src={`${process.env.NEXT_PUBLIC_YANDEX_STORAGE_URL}/${folder}/${option.alias || option.value}.webp`}
                alt={option.label}
                unoptimized={true}
                width={size}
                height={size}
                onError={(e) => {
                    e.currentTarget.src = "/img/placeholder-brand.png";
                    e.currentTarget.srcset = "/img/placeholder-brand.png";
                }}
            />
            <span className={classes.IconLabel__name}>{option.label}</span>
        </div>
    );
};